import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid, IconButton, Tooltip } from '@mui/material/';
import { HomeLink, LinkedInLink, KontaktLink, XingLink } from './Links';
import Toolbar from '@mui/material/Toolbar';

function Copyright(props) {
  const { children } = props;
  Copyright.propTypes = {
    children: PropTypes.string.isRequired,
  };
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      {HomeLink.toWrappedNavLink({ color: 'inherit' }, children)}{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {},
  toolbarTitle: {
    flex: 1,
    padding: theme.spacing(0),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    padding: theme.spacing(0, 0),
  },
  linkIcon: {
    '&:hover': {
      backgroundColor: '#e1ecff',
    },
  },
}));

function Footer(props) {
  const classes = useStyles();
  const { description, title } = props;

  return (
    <footer className={classes.footer}>
      <React.Fragment>
        <Container maxWidth='lg' >
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={0} justifyContent='space-between'>
              <Grid xs={12} item>
                <Typography
                  className={classes.toolbarTitle}
                  variant='h6'
                  align='center'
                  color='inherit'
                  noWrap
                >
                  {HomeLink.toWrappedNavLink({ color: 'inherit' }, title)}
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} item align='center'>
                <LinkIcon link={LinkedInLink} />
                <LinkIcon link={XingLink} />
                <LinkIcon link={KontaktLink} />
              </Grid>
            </Grid>
          </Toolbar>
          <Typography
            variant='subtitle1'
            align='center'
            color='textSecondary'
            component='p'
          >
            {description}
          </Typography>
          <Copyright>{title}</Copyright>
        </Container>
      </React.Fragment>
    </footer>
  );
}

function LinkIcon(props) {
  const classes = useStyles();
  const { link } = props;
  LinkIcon.propTypes = {
    link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      linkUrl: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    }).isRequired,
  };
  return (
    <Tooltip title={link.title} className={classes.linkIcon}>
      <IconButton
        component='a'
        href={link.linkUrl}
        target={link.target}
        rel='noopener noreferrer'
      >
        {link.icon}
      </IconButton>
    </Tooltip>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;
