import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function ProjectTaskList(props) {
  const { title, items } = props;
  ProjectTaskList.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.any.isRequired,
  };
  return (
    <div>
      <Typography>{title}</Typography>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Typography>{item}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
}
