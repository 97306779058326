import * as React from 'react';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';

export default function SiteImpressum() {
  return (
    <React.Fragment>
      <Container maxWidth='lg'>
        <main>
          <Typography variant='h3'>Impressum</Typography>
          <Typography variant='h4'>
            Angaben gem&auml;&szlig; &sect; 5 TMG
          </Typography>
          <Typography variant='body1'>
            CREVIO GmbH
            <br />
            Im Steinger&uuml;ck 20
            <br />
            64823 Gro&szlig;-Umstadt
            <br />
            <br />
            Registergericht: Amtsgericht Darmstadt
            <br />
            Registernummer: HRB 101625
            <br />
            Vertretungsberechtigter Geschäftsführer: Gerrit Hendrik Freise
            <br />
            Umsatzsteuer-Identifikationsnummer nach § 27a UStG: DE341136709
          </Typography>
          <Typography variant='h4'>Kontakt</Typography>
          <Typography variant='body1'>
            Telefon: 06078 7837884
            <br />
            E-Mail: info@crevio.de
          </Typography>
          <Typography variant='h4'>EU-Streitschlichtung</Typography>
          <Typography variant='body1'>
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{' '}
            <Link
              color='inherit'
              href='https://ec.europa.eu/consumers/odr'
              target='_blank'
              rel='noopener noreferrer'
              underline="hover">
              https://ec.europa.eu/consumers/odr
            </Link>
            <br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </Typography>
          <Typography variant='h4'>
            Verbraucher&shy;streit&shy;beilegung
            /Universal&shy;schlichtungs&shy;stelle
          </Typography>
          <Typography variant='body1'>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </Typography>
          <Typography variant='h5'>Haftung f&uuml;r Inhalte</Typography>{' '}
          <Typography variant='body1'>
            Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
            f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
            Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
            Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
            gespeicherte fremde Informationen zu &uuml;berwachen oder nach
            Umst&auml;nden zu forschen, die auf eine rechtswidrige
            T&auml;tigkeit hinweisen.
          </Typography>
          <Typography variant='body1'>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab
            dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
            m&ouml;glich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </Typography>
          <Typography variant='h5'>Haftung f&uuml;r Links</Typography>{' '}
          <Typography variant='body1'>
            Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf
            deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir
            f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr
            &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist
            stets der jeweilige Anbieter oder Betreiber der Seiten
            verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
            Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
            &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der
            Verlinkung nicht erkennbar.
          </Typography>
          <Typography variant='body1'>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </Typography>
          <Typography variant='h5'>Urheberrecht</Typography>
          <Typography variant='body1'>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
            bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors
            bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r
            den privaten, nicht kommerziellen Gebrauch gestattet.
          </Typography>
          <Typography variant='body1'>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </Typography>
          <Typography variant='body1'>
            Quelle:
            <Link
              color='inherit'
              href='https://www.e-recht24.de'
              target='_blank'
              rel='noopener noreferrer'
              underline="hover">
              https://www.e-recht24.de
            </Link>
          </Typography>
        </main>
      </Container>
    </React.Fragment>
  );
}
