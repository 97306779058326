import Typography from '@mui/material/Typography';
import ProjectTaskList from '../components/ProjectTaskList';
import * as Media from './../media/Media';
const projekt = {
  title: 'Entwurf eines Prototypen auf SalesForce-Basis',
  startDate: new Date('2014-08-01'),
  endDate: new Date('2014-09-30'),
  branche: 'Atomobilindustrie',
  image: Media.IMG_CAR,
  imageText: 'Branche Automobilindustrie',
  description: (
    <Typography>
      Für einen Automobilhersteller wurde ein Prototyp entwickelt, um eine
      Shoplösung für digitale Produkte in Fahrzeugen in einem PoC zu
      präsentieren.
    </Typography>
  ),
  role: <Typography>Softwareentwickler</Typography>,
  customer: <Typography>großer Automobilhersteller</Typography>,
  place: <Typography>Berlin</Typography>,
  teamsize: <Typography>2</Typography>,
  tasks: (
    <div>
      <ProjectTaskList
        title=''
        items={[
          'Anbindung einer Weboberfläche an Salesforce',
          'Design und Implementierung des Prototypen',
          'Präsentation der Ergebnisse beim Kunden zur Projektakquise',
        ]}
      />
    </div>
  ),
};

export default projekt;
