import Typography from '@mui/material/Typography';
import ProjectTaskList from '../components/ProjectTaskList';
import * as Media from './../media/Media';

const projekt = {
  title: 'Erstellung der eigenen Homepage',
  startDate: new Date('2021-01-01'),
  endDate: new Date('2021-01-31'),
  image: Media.IMG_LOGO_SVG,
  branche: 'Webentwicklung',
  imageText: 'Logo',
  description: (
    <Typography>
      Erstellung der Firmeneigenen Homepage auf Basis von rejactJS und
      material-ui.
    </Typography>
  ),
  tasks: (
    <div>
      <ProjectTaskList
        title='Softwareentwicklung'
        items={[
          'Implementierung auf Basis von rejactJS (v17.0) und material-UI (v4.11)',
          'Einsatz von GitLab zur Verwaltung des Quellcodes',
          'Einrichtung und Nutzung einer automatischen Build- und Deploypipeline in GitLab CI/CD',
          'Nutzung eigener Docker-Container zum Bauen und Deployen der Homepage',
        ]}
      />
    </div>
  ),
};

export default projekt;
