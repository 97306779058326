/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
});

function FeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;

  FeaturedPost.propTypes = {
    post: PropTypes.shape({
      date: PropTypes.string,
      description: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.object,
      linkTarget: PropTypes.string,
      linkText: PropTypes.string,
      linkTargetType: PropTypes.string,
    }).isRequired,
  };

  function createLink2(link, linkText, linkTarget, linkTargetType) {
    return null;
  }
  function createLink(link, linkText, linkTarget, linkTargetType) {
    if (link) {
      return (
        <Link
          component={NavLink}
          exact='true'
          to={link.routeUrl}
          replace
          underline="hover">
          {link.title}
        </Link>
      );
    } else if (linkText && linkTarget) {
      return (
        <Link
          href={linkTarget}
          target={linkTargetType ? linkTargetType : '_self'}
          underline="hover">
          {linkText}
        </Link>
      );
    } else {
      return null;
    }
  }

  return (
    <Grid item xs={12} md={6}>
      <Card className={classes.card}>
        <div className={classes.cardDetails}>
          <CardContent>
            <Typography component='h2' variant='h5'>
              {post.title}
            </Typography>
            <Typography variant='subtitle1' color='textSecondary'>
              {post.date}
            </Typography>
            <Typography variant='subtitle1' component='span'>
              {post.description}{' '}
            </Typography>
            <Typography paragraph />
            <Typography variant='subtitle1'>
              {createLink(
                post.link,
                post.linkText,
                post.linkTarget,
                post.linkTargetType,
              )}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </Grid>
  );
}

export default FeaturedPost;
