import * as React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { Link, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

class RouteLink extends React.Component {
  constructor(title, routeUrl, element, icon) {
    super();
    this.title = title;
    this.routeUrl = routeUrl;
    this.element = element;
    this.icon = icon;
  }

  toWrappedNavLink(props, children) {
    return (
      <Link
        component={NavLink}
        exact='true'
        replace
        to={this.routeUrl}
        {...props}
        underline="hover">
        {children}
      </Link>
    );
  }

  toListItemLink(classes) {
    return (
      <ListItem
        className={classes.listItemLink}
        button
        component={NavLink}
        to={this.routeUrl}
        exact='true'
        replace
      >
        {this.icon ? <ListItemIcon>{this.icon}</ListItemIcon> : null}
        <ListItemText primary={this.title} />
      </ListItem>
    );
  }

  toRoute() {
    return (
      <Route
        key={this.routeUrl}
        exact='true'
        path={this.routeUrl}
        element={this.element}
      />
    );
  }
  render() {
    return null;
  }
}

RouteLink.propTypes = {
  title: PropTypes.string.isRequired,
  routeUrl: PropTypes.string.isRequired,
  element: PropTypes.elementType.isRequired,
  icon: PropTypes.element.isRequired,
};

export default RouteLink;
