import Typography from '@mui/material/Typography';
import ProjectTaskList from '../components/ProjectTaskList';
import * as Media from './../media/Media';

const projekt = {
  title: 'Entwicklung eines internen Informationsportal',
  startDate: new Date('2010-12-01'),
  endDate: new Date('2013-01-31'),
  branche: 'Gebäudemanagement',
  image: Media.IMG_HOUSES,
  imageText: 'Branche Gebäudemanagement',
  description: (
    <Typography>
      DOKIM ist eine Steuerungssoftware für das Immobilienmanagement auf
      kirchlicher und kommunaler Ebene, die neben der Erfassung von Grunddaten
      und Betriebskosten auch Berechnungen, Diagramme und komplette Berichte auf
      Basis dieser Daten zur Verfügung stellt.
    </Typography>
  ),
  role: <Typography>Softwareentwickler</Typography>,
  customer: <Typography>inhouse als Angestellter</Typography>,
  place: <Typography>Darmstadt</Typography>,
  teamsize: <Typography>4</Typography>,
  tasks: (
    <div>
      <ProjectTaskList
        title='Softwareentwicklung'
        items={[
          'Implementierung unter Java 6',
          'Überarbeitung und Erweiterung der Benutzeroberfläche (Swing)',
          'Neuentwicklung, Modellierung, Überarbeitung und Erweiterung verschiedener Berechnungsmodule',
          'Wartung, Pflege und Neuentwicklung von XML-Dokumenten und -Formaten',
          'Umsetzung und Optimierung komplexer Berechnungen in SQL',
          'Einarbeitung von Sicherheitskonzepten',
          'Planung und Modellierung einer neuen Client-Server-Architektur',
        ]}
      />
    </div>
  ),
};

export default projekt;
