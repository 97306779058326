import HOMEPAGE from './2021_Homepage.js';
import DB_2016_2021 from './2016_2021_DB.js';
import DHL_2014_2016 from './2014_2016_DHL.js';
import VW_2014 from './2014_3_VW.js';
import Vodafone_2014 from './2014_2_Vodafone.js';
import TELEKOM_2014 from './2014_1_Telekom.js';
import LH_2013_2014 from './2013_2014_LH.js';
import DOKIM_2010_2013 from './2010_2013_DOKIM.js';
import SONSTIGES from './sonstiges.js';
const projects = [
  DB_2016_2021,
  HOMEPAGE,
  DHL_2014_2016,
  Vodafone_2014,
  VW_2014,
  TELEKOM_2014,
  LH_2013_2014,
  DOKIM_2010_2013,
]
  .sort((p1, p2) => sortDatesDescNullFirst(p1.endDate, p2.endDate))
  .concat(SONSTIGES);

function sortDatesDescNullFirst(date1, date2) {
  let date1ToCompare = date1 ? date1 : new Date('2999-12-31');
  let date2ToCompare = date2 ? date2 : new Date('2999-12-31');
  return date2ToCompare - date1ToCompare;
}
export {
  DB_2016_2021,
  DHL_2014_2016,
  VW_2014,
  Vodafone_2014,
  TELEKOM_2014,
  LH_2013_2014,
  DOKIM_2010_2013,
  SONSTIGES,
};
export default projects;
