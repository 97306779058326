import * as React from 'react';
import { HashRouter, Routes } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import Home from './SiteHome'; // Wird für das Rendering benötigt
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Routes as RouteLinks } from './Links';
import { Theme } from './Theme.js';

export default function App() {
  return (
    <React.Fragment>
      <Theme>
        <CssBaseline />
        <Container maxWidth='lg' >
          <HashRouter basename='/'>
            <Header title='Freise IT' />
            <Navigation />
            <Routes>{RouteLinks.map((link) => link.toRoute())}</Routes>
            <Footer
              title='CREVIO GmbH'
              description='Selbstständiger Softwareentwickler. Gerrit Hendrik Freise, Im Steingerück 20, 64823 Groß-Umstadt'
            />
          </HashRouter>
        </Container>
      </Theme>
    </React.Fragment>
  );
}
