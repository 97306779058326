import '@fontsource/barlow';
import '@fontsource/barlow-semi-condensed';
import '@fontsource/roboto';

import PropTypes from 'prop-types';

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
const theme = createTheme({
  typography: {
    fontFamily: [
      'Barlow',
      'Barlow Semi Condensed',
      'roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 14.5,
  },
});


export function Theme(props) {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

Theme.propTypes = {
  children: PropTypes.array.isRequired,
};
