import Typography from '@mui/material/Typography';
import ProjectTaskList from '../components/ProjectTaskList';
import * as Media from './../media/Media';
const projekt = {
  title:
    'Schnittstellenimplementierung zur Verwaltung und Integration von Recording-Plattformen (IPTV)',
  startDate: new Date('2014-03-01'),
  endDate: new Date('2014-07-31'),
  branche: 'Telko/Medien, IPTV',
  image: Media.IMG_TV2,
  imageText: 'Branche Telko/Medien',
  description: (
    <Typography>
      Um im IPTV-Bereich eines großen deutschen Telekommunikationsunternehmen
      mobile Anwendungen das Aufnehmen und Anschauen von TV-Aufzeichnungen zu
      ermöglichen, wurde ein System entworfen, die zwischen den
      Aufnahmepattformen (Cloud-Lösung) und den unterschiedlichen Endgeräten
      sitzt.
    </Typography>
  ),
  role: <Typography>Softwareentwickler</Typography>,
  customer: (
    <Typography>großes deutsches Telekommunikationsunternehmen</Typography>
  ),
  place: <Typography>Darmstadt</Typography>,
  teamsize: <Typography>10</Typography>,
  tasks: (
    <div>
      <Typography>Softwareentwicklung auf Basis von Java 7</Typography>
      <ProjectTaskList
        title='Softwareentwicklung'
        items={[
          'Backend-Entwicklung auf Basis von Java 7 & Spring',
          'Anbindung von Schnittstellen via REST',
          'Einsatz von Git als Versionsverwaltungssystem',
        ]}
      />
      <ProjectTaskList
        title='Agile Methoden und Praktiken'
        items={['Arbeiten nach Scrumban, eine Mischung aus Scrum und Kanban']}
      />
    </div>
  ),
};

export default projekt;
