import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FeaturedPost from './FeaturedPost';
import Panel from './Panel';
import { IMG_LOGO_SVG, PDF_CV } from './../media/Media';
import { ProjekteLink, CSM, ACSM, CSP_SM, CSPO } from './Links';
import { IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(() => ({
  badge: {
    '&:hover': {
      backgroundColor: '#e1ecff',
    },
  },
}));
const featuredPosts = [
  {
    title: 'Projekte',
    description:
      'Die übersicht meiner bisherigen Projekte und Tätigkeiten finden Sie in meiner Projektübersicht.',
    link: ProjekteLink,
  },
  {
    title: 'Verfügbarkeit & Lebenslauf',
    description:
      'Verfügbarkeit nach Absprache. Meinen aktuellen Lebenslauf finden Sie als PDF hier zum Download:',
    linkText: 'Lebenslauf',
    linkTarget: PDF_CV,
    linkTargetType: '_blank',
  },
  {
    title: 'Agile Expertise',
    description: (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='subtitle1' paragraph>
            Durch meine Projekttätigkeiten und meine Zertifizierungen bei der
            Scrum Alliance, bin ich der perfekte Begleiter bei allen agilen
            Fragestellungen
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Badge link={CSM} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Badge link={ACSM} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Badge link={CSP_SM} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Badge link={CSPO} />
        </Grid>
      </Grid>
    ),
  },
];

function Badge(props) {
  const classes = useStyles();
  const { icon } = props.link;
  const { linkUrl } = props.link;
  const { title } = props.link;
  Badge.propTypes = {
    link: PropTypes.shape({
      icon: PropTypes.string.isRequired,
      linkUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  };
  return (
    <Tooltip title={title}>
      <IconButton
        component='a'
        href={linkUrl}
        target='_blank'
        rel='noopener noreferrer'
        className={classes.badge}
        size="large">
        <img src={icon} height='110' alt=''></img>
      </IconButton>
    </Tooltip>
  );
}

const willkommen = {
  title: 'Willkommen',
  description:
    'CREVIO GmbH - Ihr kompetenter Partner in der Softwareentwicklung',
  image: IMG_LOGO_SVG,
};
export default function SiteHome() {
  return (
    <React.Fragment>
      <Container maxWidth='lg' >
        <main>
          <Panel post={willkommen} />

          <Grid container spacing={4}>
            {featuredPosts.map((post, index) => (
              <FeaturedPost key={index} post={post} />
            ))}
          </Grid>
        </main>
      </Container>
    </React.Fragment>
  );
}
