import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
  collapsedCard: {
    display: 'flex',
  },
  media: {
    height: 0,
    paddingTop: '30%', // 16:9
    maxHeight: 2,
    backgroundColor: theme.palette.grey[500],
  },
  collapsedCardHeader: {
    verticalAlign: 'top',
  },
  cardHeader: {},
  card: {},
  root: {
    width: 100000,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function CollapsibleCard(props) {
  const classes = useStyles();
  const { content } = props;
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          title={content.title}
          subheader={content.subtitle}
          className={classes.cardHeader}
        />
        <CardMedia
          className={classes.media}
          image={content.image}
          title={content.imageText}
        />
        <CardContent>{content.shortDescription}</CardContent>
        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
            size="large">
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Divider />
          <CardContent>{content.collapsibleContent}</CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}

CollapsibleCard.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    shortDescription: PropTypes.object.isRequired,
    collapsibleContent: PropTypes.object.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
  }).isRequired,
};
