import Typography from '@mui/material/Typography';
import ProjectTaskList from '../components/ProjectTaskList';
import * as Media from './../media/Media';
const projekt = {
  title: 'Entwicklung eines internen Informationsportal',
  startDate: new Date('2013-04-01'),
  endDate: new Date('2014-01-31'),
  branche: 'Luftfahrtbranche',
  image: Media.IMG_AIRCRAFT,
  imageText: 'Branche Luftfahrt',
  description: (
    <Typography>
      Um im IPTV-Bereich eines großen deutschen Telekommunikationsunternehmen
      mobile Anwendungen das Aufnehmen und Anschauen von TV-Aufzeichnungen zu
      ermöglichen, wurde ein System entworfen, die zwischen den
      Aufnahmepattformen (Cloud-Lösung) und den unterschiedlichen Endgeräten
      sitzt.
    </Typography>
  ),
  role: <Typography>Softwaretester, Stellvertretender Testmanager</Typography>,
  customer: <Typography>große deutsche Airline</Typography>,
  place: <Typography>Frankfurt a.M.</Typography>,
  teamsize: <Typography>12 (Deutschland: 5, Polen: 7)</Typography>,
  tasks: (
    <div>
      <Typography>Softwareentwicklung auf Basis von Java 7</Typography>
      <ProjectTaskList
        title='Test und Testmanagement'
        items={[
          'Entwicklung von Testideen und Testfällen in Testlink',
          'Testen der auf Basis von Adobe CQ5 entwickelten Software im Black-Box-Verfahren',
          'Analyse von vorhandenen Fehlern und Mängeln',
          'Issue-Management unter Einsatz von Jira und HP Qualitycenter',
        ]}
      />
      <ProjectTaskList
        title='Anforderungsmanagement und Dokumentation'
        items={[
          'Koordination und Überwachung von Arbeitspaketen',
          'Anleitung von Mitarbeitern im Nearshore-Center in Polen auf fachlicher Ebene',
          'Erstellung und Optimierung des initialen Rechte- und Rollenkonzeptes',
          'Erstellung und Bearbeitung kundenspezifischer Dokumentation',
          'Erstellung und Bearbeitung von Schulungsunterlagen',
        ]}
      />
    </div>
  ),
};

export default projekt;
