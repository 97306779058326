import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { Routes } from './Links';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'left',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  listItemLink: {
    '&:hover': {
      backgroundColor: '#e1ecff',
    },
  },
}));

function Navigation() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Toolbar
        component='nav'
        variant='dense'
        className={classes.toolbarSecondary}
      >
        <List className={classes.flexContainer}>
          {Routes.map((link, index) => (
            <li key={index}>{link.toListItemLink(classes)}</li>
          ))}
        </List>
      </Toolbar>
    </React.Fragment>
  );
}

export default Navigation;
