import Typography from '@mui/material/Typography';
import ProjectTaskList from '../components/ProjectTaskList';
import * as Media from './../media/Media';
const projekt = {
  title: 'Portal-, Backend- und App-Entwicklung zum Betrieb von Paketshops',
  startDate: new Date('2014-09-01'),
  endDate: new Date('2016-05-31'),
  branche: 'Logistik',
  image: Media.IMG_POSTBOX,
  imageText: 'text',
  description: (
    <Typography>
      Kiosk-/Shopbesitzern in Europa soll ermöglicht werden via eine einfache
      App Pakete entgegen zu nehmen und diese ebenfalls ausgeben zu können.
      Hierzu wurde eine App auf Basis von Android und iOS und ein Backend zur
      Entgegennahme und Weiterleitung der Daten an die bestehende
      IT-Infrastruktur der verschiedenen Länder entwickelt. Zusätzlich wurde ein
      Portal auf Basis von Liferay entwickelt um Mitarbeitern im Backoffice
      sowie Shopbesitzern verschiedene Daten, Reports und Rechnungen zur
      Verfügung zu stellen. Die Applikation wurde zunächst als Pilot in Belgien
      ausgerollt, mittlerweile befindet sich diese dort im Live-Betrieb. Später
      erfolgte die Anbindung der Länder Österreich und Niederlande. Stand
      05/2016 wurde mit den Vorbereitungen zur Anbindung weiterer Länder wie
      Tschechien und Slowakei sowie zur Konsolidierung und Integration des
      Partnermanagementsystems auf Basis von Salesforce begonnen.
    </Typography>
  ),
  role: <Typography>Softwareentwickler & -Architekt</Typography>,
  customer: (
    <Typography>
      großes deutsches Logistikunternehmen, Abteilung Europa
    </Typography>
  ),
  place: <Typography>Frankfurt a.M.</Typography>,
  teamsize: <Typography>12 (Deutschland: 11, Polen: 1)</Typography>,
  tasks: (
    <div>
      <ProjectTaskList
        title='Softwareentwicklung & -architektur'
        items={[
          'Architekt für die Entwicklung des Portals auf Basis von Liferay 6.2 EE',
          'Technologie-Experte im Bereich Liferay',
          'Lead-Developer im Projekt',
          'Mitbestimmung der Architektur des Backend-Systems zur Anbindung der verschiedenen Bestandssysteme auf Basis von Spring Boot und Java 7',
          'Einsatz von REST und SOAP als Schnittstellentechnologie',
          'Einsatz von SQL und Oracle DB zur Persistenz der Daten',
          'Nutzung von SVN und später Git zur Verwaltung des Quellcodes',
        ]}
      />
      <ProjectTaskList
        title='Agile Methoden und Praktiken sowie Teamleitung'
        items={[
          'Transformation des Projektes von einer klassischen Vorgehensweise zu Scrum',
          'Anleitung von neuen Teammitgliedern, die in das Projekt einsteigen',
        ]}
      />
      <ProjectTaskList
        title='Sonstiger Tooleinsatz'
        items={[
          'Einsatz von HP ALM / Qualitycenter zur Verwaltung von Testfällen und Defects',
          'Einsatz von Enterprise Architect zur Erfassung der übergreifenden Architektur',
          'Betreuung des Pilotbetriebs in verschiedenen Betriebszentralen',
        ]}
      />
      <ProjectTaskList
        title='Kundenmanagement'
        items={[
          'Initial Einsatz von HP Qualitycenter zur Verwaltung der Anforderungen, später Einsatz von Atlassian Jira',
        ]}
      />
    </div>
  ),
};

export default projekt;
