import Typography from '@mui/material/Typography';
import * as Media from './../media/Media';
const projekt = {
  title:
    'Backendentwicklung zum Empfang, Verarbeitung und Speicherung von Reports (IPTV)',
  startDate: new Date('2014-07-01'),
  endDate: new Date('2014-08-01'),
  branche: 'Telko/Medien, IPTV',
  image: Media.IMG_TV,
  imageText: 'Branche Telko/Medien',
  description: (
    <Typography>
      Um im IPTV-Bereich eines großen Telekommunikationsunternehmen
      Quality-Reports empfangen, verarbeiten und speichern zu können, wurde eine
      Backendanwendung entwickelt, die diese Reports entgegennimmt, verarbeitet
      und in ein speicherfähiges Format übersetzt. Hauptschwierigkeit war hier
      das korrekte Übersetzen der empfangenen Binärdaten.
    </Typography>
  ),
  role: <Typography>Softwareentwickler</Typography>,
  customer: <Typography>großes Telekommunikationsunternehmen</Typography>,
  place: <Typography>Frankfurt a.m.</Typography>,
  teamsize: <Typography>2</Typography>,
  tasks: (
    <div>
      <Typography>Softwareentwicklung auf Basis von Java 7</Typography>
    </div>
  ),
};

export default projekt;
