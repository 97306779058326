import { Mail as MailIcon } from '@mui/icons-material';
import { Home as HomeIcon } from '@mui/icons-material';
import { Work as WorkIcon } from '@mui/icons-material';
import { Gavel as GavelIcon } from '@mui/icons-material';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import SiteHome from './SiteHome';
import SiteProjekte from './SiteProjekte';
import SiteImpressum from './SiteImpressum';
import * as Media from './../media/Media';

import RouteLink from './RouteLink';

export const HomeLink = new RouteLink('Home', '/', <SiteHome />, <HomeIcon />);

export const ImpressumLink = new RouteLink(
  'Impressum',
  '/impressum',
  <SiteImpressum />,
  <GavelIcon />,
);

export const ProjekteLink = new RouteLink(
  'Projekte',
  '/projekte',
  <SiteProjekte />,
  <WorkIcon />,
);

export const Routes = [HomeLink, ProjekteLink, ImpressumLink];

export const KontaktLink = {
  title: 'Kontakt - mailto:kontakt@crevio.de',
  linkUrl: 'mailto:kontakt@crevio.de',
  icon: <MailIcon />,
  target: '_blank',
};

export const XingLink = {
  title: 'XING Profil - Gerrit Hendrik Freise',
  linkUrl: 'https://www.xing.com/profile/GerritHendrik_Freise',
  icon: <img height='24px' src={Media.IMG_XING} alt='' />,
  target: '_blank',
};

export const LinkedInLink = {
  title: 'LinkedIn® Profil - Gerrit Hendrik Freise',
  linkUrl: 'https://www.linkedin.com/in/gerrit-hendrik-freise',
  icon: <img height='24px' src={Media.IMG_LINKEDIN} alt='' />,
  target: '_blank',
};

export const CVLink = {
  title: 'CV',
  linkUrl: Media.PDF_CV,
  icon: <AccountCircleIcon />,
  target: '_blank',
};

export const CSP_SM = {
  title: 'Certified Scrum Professional - ScrumMaster (CSP-SM)',
  linkUrl: 'https://bcert.me/slysaphhf',
  icon: Media.BADGE_CSP_SM,
};

export const CSM = {
  title: 'Certified ScrumMaster (CSM)',
  linkUrl: 'https://bcert.me/sjjaiepxd',
  icon: Media.BADGE_CSM,
};

export const ACSM = {
  title: 'Advanced Certified ScrumMaster (A-CSM',
  linkUrl: 'https://bcert.me/sdrcatbxl',
  icon: Media.BADGE_ACSM,
};

export const CSPO = {
  title: 'Certified Scrum Product Owner (CSPO)',
  linkUrl: 'https://bcert.me/sxvjxywly',
  icon: Media.BADGE_CSPO,
};
