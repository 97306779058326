import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CollapsibleProjectCard from './CollapsibleProjectCard';
import projects from '../projects/index';
import Panel from './Panel';
import { IMG_PROJECT, PDF_CV } from '../media/Media';
import { Link } from '@mui/material';

const panelContent = {
  title: 'Projektliste',
  description: (
    <div>
      Hier ist ein Auszug aller meiner Projekte zu finden, die ich als
      angestellter oder selbsständiger Softwareentwickler erledigt habe. Details
      können auch aus meinem{' '}
      <Link href={PDF_CV} target='_blank' underline="hover" >
        Lebenslauf
      </Link>{' '}
      
      entnommen werden.
    </div>
  ),

  image: IMG_PROJECT,
};

export default function SiteProjekte() {
  return (
    <React.Fragment>
      <Container maxWidth='lg'>
        <main>
          <Panel post={panelContent} />
          <Grid container spacing={4}>
            {projects.map((project, index) => (
              <CollapsibleProjectCard key={index} project={project} />
            ))}
          </Grid>
        </main>
      </Container>
    </React.Fragment>
  );
}
