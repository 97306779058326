import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import CollapsibleCard from './CollapsibleCard';

function createCollapsibleContent(project) {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {tableEntry('Rollen/Positionen', project.role)}
          {tableEntry('Kune', project.customer)}
          {tableEntry('Einsatzort', project.place)}
          {tableEntry('Teamgröße', project.teamsize)}
          {tableEntry('Aufgaben', project.tasks)}
          {additionalTableEntries(project)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function tableEntry(title, content, index) {
  if (title && content) {
    return (
      <TableRow key={index ? index : title}>
        <TableCell style={{ verticalAlign: 'top' }}>
          <Typography>{title}</Typography>
        </TableCell>
        <TableCell style={{ verticalAlign: 'top' }}>{content}</TableCell>
      </TableRow>
    );
  }
  return null;
}

function additionalTableEntries(project) {
  if (project.additionalTableEntries) {
    return project.additionalTableEntries.map((entry, index) =>
      tableEntry(entry.title, <Typography>{entry.content}</Typography>, index),
    );
  } else {
    return null;
  }
}

function createSubTitle(project) {
  let startMonth =
    project.startDate.getMonth() + 1 < 10
      ? '0' + (project.startDate.getMonth() + 1)
      : '' + (project.startDate.getMonth() + 1);
  let startDate = startMonth + '/' + project.startDate.getFullYear();
  let endDate = 'heute';
  if (project.endDate) {
    let endMonth =
      project.endDate.getMonth() + 1 < 10
        ? '0' + (project.endDate.getMonth() + 1)
        : '' + (project.endDate.getMonth() + 1);
    endDate = endMonth + '/' + project.endDate.getFullYear();
  }
  let dateString = startDate + ' bis ' + endDate;
  if (startDate === endDate) {
    dateString = startDate;
  }
  return dateString + ', ' + project.branche;
}

export default function CollapsibleProjectCard(props) {
  const { project } = props;
  const content = {
    title: project.title,
    subtitle: createSubTitle(project),
    shortDescription: project.description,
    collapsibleContent: createCollapsibleContent(project),
    image: project.image,
    imageText: project.imageText,
  };

  return <CollapsibleCard content={content} />;
}

CollapsibleProjectCard.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date),
    description: PropTypes.element,
    role: PropTypes.element,
    teamsize: PropTypes.element,
    customer: PropTypes.element,
    place: PropTypes.element,
    tasks: PropTypes.element,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    additionalTableEntries: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};
