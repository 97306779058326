import Typography from '@mui/material/Typography';
import * as Media from './../media/Media';
const projekt = {
  title: 'Zusammenfassung weiterer Projekte',
  startDate: new Date('2010-05-01'),
  endDate: new Date('2014-03-30'),
  branche: 'Gemischt',
  image: Media.IMG_LEGO,
  imageText: 'Gemischt',
  description: (
    <Typography>Auflistung aller sonstigen Tätigkeiten und Projekte</Typography>
  ),
  additionalTableEntries: [
    {
      title: '02/2014 bis 03/2014',
      content: 'Vorbereitung einer CQ5-Schulung',
    },
    {
      title: '10/2012 bis 01/2013',
      content:
        'Masterarbeit: Analysis and Evaluation of Ranking Strategies for Chain Completion Proposals',
    },
    {
      title: '04/2011 bis 09/2011',
      content:
        'Plugin-Entwicklung: Implementierung einer flexiblen Advanced Configuration-Page für die Eclipse JDT Content Assist Lists',
    },
    {
      title: '05/2010 bis 09/2011',
      content:
        'Bachelorarbeit: Konzeption und Implementierung eines grafischen Editors für Composite Model Refactorings in Eclipse',
    },
  ],
};

export default projekt;
